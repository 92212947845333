.breadcrumb {
    font-size: 14px;
    margin: 16px 0;

    .breadcrumb-item + .breadcrumb-item::before {
        content: '>';
        font-weight: initial;
    }
    .breadcrumb-item {
        color: var(--color-gray) !important;
    }
    .breadcrumb-item:last-child {
        font-weight: bold;
    }
}
