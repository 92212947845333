// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

:root {
    --color-primary: #ffe600;
    --color-dark: #333333;
    --color-gray-dark: #666666;
    --color-gray: #999999;
    --color-light: #eeeeee;
    --color-blue: #3483fa;
}

body {
    background-color: var(--color-light);
}
