.product {
    border-bottom: 2px solid var(--color-light);
    margin: 0 16px;
    padding: 16px 0;
    position: relative;

    .detail-product,
    a:hover,
    a {
        color: var(--color-dark);
    }
    .thumbnail {
        border-radius: 4px;
        margin-right: 16px;
    }
    .price {
        font-size: 24px;
        margin-bottom: 32px;
    }
    h2,
    .status {
        font-weight: initial;
        font-size: 18px;
    }
    small {
        color: var(--color-gray-dark);
        font-size: 12px;
    }
    .icon-shipping {
        margin-left: 16px;
        margin-top: -5px;
    }
}
.product:last-child {
    border: none;
}
